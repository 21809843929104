import * as React from 'react';
import { useState } from 'react';

import { graphql } from 'gatsby';
import queryString from 'query-string';
import { useFlexSearch } from 'react-use-flexsearch';

import Container from '../components/Container';
import Gallery from '../components/Gallery';
import Layout from '../components/Layout';
import Paragraph from '../components/Paragraph';
import SearchBar from '../components/SearchBar';

const unFlattenResults = results =>
  results.map(item => {
    const { id, description, title, url } = item;
    return { id, description, title, file: { url } };
  });

const SearchPage = ({ location, data }) => {
  const {
    localSearchPages: { index, store },
  } = data;

  const query = queryString.parse(location.search).s;

  const [searchQuery, setSearchQuery] = useState(query || '');

  const results = useFlexSearch(searchQuery, index, store);

  const gallery = searchQuery ? unFlattenResults(results) : [];

  return (
    <Layout
      pagetitle={`Search results for: ${searchQuery}`}
      title={`Search results for: ${searchQuery}`}
    >
      <Container>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Container>
      <Container>
        {searchQuery !== '' && results.length === 0 ? (
          <Paragraph>no results</Paragraph>
        ) : (
          <Gallery gallery={gallery} />
        )}
      </Container>
    </Layout>
  );
};

export default SearchPage;

export const query = graphql`
  query {
    localSearchPages {
      index
      store
    }
  }
`;
