import React, { useCallback, useEffect, useState } from 'react';
import * as styles from './Gallery.module.scss';

const GalleryItem = ({ file, index, title, showImage }) => (
  <div className={styles.imageContainer} onClick={() => showImage(index)}>
    <div className={styles.galleryImgWrapper}>
      <div className={styles.galleryImg}>
        <img
          alt={title}
          className={styles.imageTag}
          loading="lazy"
          src={`${file.url}?fm=jpg&fl=progressive&w=300`}
          width="300"
        />
      </div>
    </div>
    <div className={styles.imageTitle}>{title}</div>
  </div>
);

export const Gallery = ({ gallery }) => {
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [imageToShow, setImageToShow] = useState({});
  const [currIndex, setCurrIndex] = useState(-1);

  const showImage = useCallback(
    index => {
      setCurrIndex(index);
      setImageToShow(gallery[index]);
      setLightBoxDisplay(true);
    },
    [gallery],
  );

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  const showNext = useCallback(
    e => {
      e.stopPropagation();
      const nextIndex = currIndex + 1;
      showImage(nextIndex > gallery.length - 1 ? 0 : nextIndex);
    },
    [gallery.length, currIndex, showImage],
  );

  const showPrev = useCallback(
    e => {
      e.stopPropagation();
      const prevIndex = currIndex - 1;
      showImage(prevIndex < 0 ? gallery.length - 1 : prevIndex);
    },
    [gallery.length, currIndex, showImage],
  );

  const handleKeyDown = useCallback(
    e => {
      if (lightboxDisplay) {
        const { key } = e;
        switch (key) {
          case 'ArrowRight':
            showNext(e);
            break;
          case 'ArrowLeft':
            showPrev(e);
            break;
          case 'Escape':
            hideLightBox();
            break;
          default:
            break;
        }
      }
    },
    [lightboxDisplay, showNext, showPrev],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className={styles.root}>
      <div>
        {gallery.map((item, index) => (
          <GalleryItem
            index={index}
            key={item.id}
            showImage={showImage}
            {...item}
          />
        ))}
      </div>
      {lightboxDisplay && (
        <div className={styles.lightbox} onClick={hideLightBox}>
          <img
            alt={imageToShow.title}
            className={styles.lightboxImg}
            src={`${imageToShow.file.url}?fm=jpg&fl=progressive&w=600`}
          />
          <button className={styles.prevButton} onClick={showPrev}>
            ⭠
          </button>
          <button className={styles.nextButton} onClick={showNext}>
            ⭢
          </button>
          <div className={styles.lightboxTitle}>{imageToShow.title}</div>
        </div>
      )}
    </div>
  );
};
