// extracted by mini-css-extract-plugin
export var galleryImg = "Gallery-module--galleryImg--Upfap";
export var galleryImgWrapper = "Gallery-module--galleryImgWrapper--XNgIB";
export var imageContainer = "Gallery-module--imageContainer--yE5ml";
export var imageTag = "Gallery-module--imageTag--HiuO6";
export var imageTitle = "Gallery-module--imageTitle--KBPkW";
export var lightbox = "Gallery-module--lightbox--4FroH";
export var lightboxImg = "Gallery-module--lightboxImg--R000c";
export var lightboxTitle = "Gallery-module--lightboxTitle--OF9d-";
export var nextButton = "Gallery-module--nextButton--gLATJ";
export var prevButton = "Gallery-module--prevButton--RlWUP";
export var root = "Gallery-module--root--c1MF0";