import * as React from 'react';
import * as styles from './SearchBar.module.scss';

import Textfield from '../Textfield';
import Button from '../Button';
import { SearchIcon } from '../../icons';

export const SearchBar = ({ searchQuery, setSearchQuery }) => (
  <form action="/search" autoComplete="off" method="get">
    <div className={styles.flexContainer}>
      <div className={styles.searchBox}>
        <Textfield
          icon={<SearchIcon />}
          id="header-search"
          name="s"
          nomargin
          onInput={e => setSearchQuery(e.target.value)}
          placeholder="Search Stella Sweets"
          type="search"
          value={searchQuery}
        />
      </div>
      <div>
        <Button type="submit">Search</Button>
      </div>
    </div>
  </form>
);
